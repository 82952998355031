export default class DatalayerComponent {
    constructor() {
        DatalayerComponent.search()
    }

    static search() {
        let timeoutId = null

        $(document).on('input', '.iris-advanced-search-input__field', function () {
            const val = $(this).val()
            clearTimeout(timeoutId)

            timeoutId = setTimeout(
                function () {
                    if ($(this).val() === val) {
                        dataLayer.push({
                            event: 'search',
                            search_type: 'generic',
                            search_term: val.substring(0, 100),
                        })
                    }
                }.bind(this),
                2000,
            )
        })
    }
}
