export default class Navbar {
    constructor() {
        Navbar.sticky()
        Navbar.submenu()
        Navbar.burger()
        Navbar.backToTop()
    }

    static submenu() {
        const body = 'body'
        const item = '[data-element="menu-item"]'
        const link = '[data-element="menu-item-link"]'

        $('[data-element="menu-item"] > a').on('click', function () {
            const idMenu = $(this).parent().data('menu-id')

            // Check if level 1 has child
            if ($(this).parent(item).hasClass('has-submenu')) {
                // desktop actions
                if (window.matchMedia('(min-width:1000px)').matches) {
                    Navbar.loadLangSwitcher(idMenu)
                    Navbar.loadSubmenuRightColumn(idMenu)
                }
                // mobile actions
                if (window.matchMedia('(max-width:1000px)').matches) {
                    $(this).parent(item).find('[data-trigger="wpiris-menu-more"]').trigger('click')
                }

                // Check if submenu is already open
                if (!$(this).parent(item).hasClass('has-submenu--current')) {
                    $(item).removeClass('has-submenu--current')
                    $(this).parent(item).addClass('has-submenu--current')
                    $(body).addClass('submenu-hover')
                    $(this).addClass('active-link')
                    return false
                }
                $(item).removeClass('has-submenu--current')
                $(body).removeClass('submenu-hover')
                $(this).removeClass('active-link')
                return false
            }
        })

        $(body).prepend('<div class="menu_mask" title="Close menu"></div>')
        $('.menu_mask').on('click', () => {
            $(body).removeClass('submenu-hover')
            $(item).removeClass('has-submenu--current')
        })
        $('.menu__item__submenu__content__close').on('click', () => {
            $(body).removeClass('submenu-hover')
            $(item).removeClass('has-submenu--current')
        })
    }

    static sticky() {
        let last_scroll_top = 0

        $(window).on('load scroll', function () {
            const offset = 120 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scroll_top = $(this).scrollTop()
            const full = false

            //Hidden comportment
            if (scroll_top >= last_scroll_top && scroll_top > navbar.outerHeight()) {
                $('body').removeClass('header-is-visible').addClass('header-is-hidden')
            } else {
                $('body').addClass('header-is-visible').removeClass('header-is-hidden')
            }

            last_scroll_top = scroll_top

            if (scroll_top > offset) {
                navbar.removeClass('header__navbar--ontop').addClass('header__navbar--onscroll')
                $('.anchors').removeClass('anchors--ontop').addClass('anchors--onscroll')
            } else {
                navbar.removeClass('header__navbar--onscroll').addClass('header__navbar--ontop')
                $('.anchors').removeClass('anchors--onscroll').addClass('anchors--ontop')
            }
        })

        $(window).on('scroll', () => {
            const $stickyDiv = $('.anchors')
            if ($stickyDiv.length > 0) {
                const offsetTop = $stickyDiv.offset().top
                const scrollTop = $(window).scrollTop()
                const topPositionInView = offsetTop - scrollTop

                if (topPositionInView === 0 || topPositionInView === 84) {
                    $stickyDiv.addClass('anchors--is-sticky')
                } else {
                    $stickyDiv.removeClass('anchors--is-sticky')
                }
            }
        })
    }

    static burger() {
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        $(trigger).on('click', () => {
            if (!$('body').hasClass('menu-open')) {
                Navbar.moveLastMenuItem()
                $('body').addClass('submenu-hover')
            } else {
                $('body').removeClass('submenu-hover')
            }
            $('body').toggleClass('menu-open')
        })

        $(more).on('click', function () {
            const idMenu = $(this).parent().data('menu-id')
            setTimeout(() => {
                $('.menu').scrollTop(0)
                Navbar.loadSubmenuRightColumn(idMenu)
            }, 10)
            $(this).next(submenu).toggleClass('submenu-open')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
        })
    }

    static moveLastMenuItem() {
        const htmlData = $('.menu__item[data-open-menu-mobile]')
            .find('[data-element="submenu__content__links"]')
            .html()
        $('.menu__mobile__sejour').html(htmlData)
    }

    static backToTop() {
        $(window).on('load scroll', function () {
            const scroll_top = $(this).scrollTop()
            if (scroll_top > 70) {
                $('.backtotop').fadeIn('is-visible')
            } else {
                $('.backtotop').fadeOut('is-visible')
            }
        })
    }

    static loadLangSwitcher(idMenu) {
        $.ajax({
            url: IRISCollectionTheme.ajaxUrl,
            data: {
                action: 'get_lang_switcher',
                datas: {
                    page_id: wpetUtils.idPage,
                },
            },
            method: 'POST',
        })
            .done((response) => {
                $(`[data-menu-id="${idMenu}"]`).find('.menu__item__submenu__content__langs').html(response)
            })
            .fail((response) => {
                console.log(response)
            })
    }

    static loadSubmenuRightColumn(idMenu) {
        $.ajax({
            url: IRISCollectionTheme.ajaxUrl,
            data: {
                action: 'get_right_submenu_column',
                datas: {
                    menu_id: idMenu,
                    page_id: wpetUtils.idPage,
                },
            },
            method: 'POST',
        })
            .done((response) => {
                $(`[data-menu-id="${idMenu}"]`).find('.menu__item__submenu__content__right_column').html(response)
            })
            .fail((response) => {
                console.log(response)
            })
    }
}
